import { useState, useEffect } from "react";

import { apiRoot } from "../constants";
import { useAuth } from "./useAuth";

const useDashboardProfile = () => {
  const [profile, setProfile] = useState(null);
  const [error, setError] = useState(false);

  const { token } = useAuth();

  useEffect(() => {
    fetch(`${apiRoot}/auth/user/`, {
      headers: {
        Authorization: `Token ${token}`,
        "Content-Type": "application/json",
      },
    })
      .then((resp) => {
        if (resp.status === 200) {
          resp.json().then((json) => {
            setProfile(json);
            setError(false);
          });
        } else {
          setError(true);
          setProfile(null);
        }
      })
      .catch((err) => {
        setError(true);
        setProfile(null);
      });
  }, [token]);

  return [profile, error];
};

export default useDashboardProfile;
