import { useContext, useState } from "react";

import { Link, useNavigate, useParams } from "react-router-dom";

import { useAuth } from "../hooks/useAuth";
import useEvent from "../hooks/useEvent";

import { ToastContext } from "../Components/ToastProvider";

import moment from "moment-timezone";

import Modal from "../Components/Modal";

import "../App.css";

function Event(toastId) {
  const { authed } = useAuth();
  const { id } = useParams();

  const { createToast, dismissToast } = useContext(ToastContext);

  const {
    event,
    error,
    joinEvent,
    hasJoinedEvent,
    unjoinEvent,
    isOwner,
    cancelEvent,
  } = useEvent(id);

  const [disabled, setDisabled] = useState(false);
  const [isModalOpen, toggleIsModalOpen] = useState(false);

  let navigate = useNavigate();

  const delay = async (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  const inThreeHours = moment().add(3, "h");

  function handleJoinEvent() {
    joinEvent(id).then(async (success) => {
      setDisabled(true);
      await delay(500);
      createToast("You succesfully joined this event 😊", "success");
      await delay(3000);
      dismissToast(toastId);
      setDisabled(false);
    });
  }

  function handleUnjoinEvent() {
    unjoinEvent(id).then(async (success) => {
      setDisabled(true);
      await delay(500);
      createToast("You succesfully left this event", "success");
      await delay(3000);
      dismissToast(toastId);
      setDisabled(false);
    });
  }

  function handleCancelEvent() {
    toggleIsModalOpen(!isModalOpen);
    cancelEvent(id).then(async (success) => {
      setDisabled(true);
      await delay(500);
      createToast("You canceled this event", "success");
      createToast("You canceled this event", "success");
      await delay(3000);
      navigate(`../../events/`, { replace: true });
      dismissToast(toastId);
      dismissToast(toastId);
    });
  }

  return (
    <>
      <Modal
        title="Are you sure about canceling this event?"
        isOpen={isModalOpen}
        handleDismiss={() => toggleIsModalOpen(!isModalOpen)}
      >
        <div className="btn-inline">
          <button className="warning-btn" onClick={handleCancelEvent}>
            Yes
          </button>
          <button onClick={() => toggleIsModalOpen(!isModalOpen)}>No</button>
        </div>
      </Modal>
      {error && <p className="error-message">Something went wrong...</p>}

      {event ? (
        <div className="block">
          <h1>{event.title}</h1>
          <h3 className="subheader">Timezone</h3>
          <p>{event.timezone}</p>
          <h3 className="subheader">Date & Time</h3>
          {event.date && (
            <p>
              {/* We show the event time in the event's timezone for every user, since it's an in person event
              and joining it is only possible at the event's timezone */}
              {moment(event.date)
                .tz(event.timezone)
                .format("dddd, MMMM Do · h:mm a")}
            </p>
          )}

          <h3 className="subheader">City</h3>
          <span>{event.location.city.title}</span>
          <h3 className="subheader">Restaurant</h3>
          <Link to={`/restaurants/${event.location.id}`}>
            <span>{event.location.title}</span>
          </Link>
          <h3 className="subheader">Address</h3>
          <span>{event.location.address}</span>
          <h3 className="subheader">What I'd like to try</h3>
          <Link to={`/dishes/${event.dish?.id}`}>
            <span>{event.dish?.title}</span>
          </Link>
          <h3 className="subheader">Event Language(s)</h3>
          <span>{event.languages}</span>
          <h3 className="subheader">Who's coming</h3>
          <ul>
            {event.clients.map((client, i) => (
              <Link to={`/users/${client.username}`} key={i}>
                <li className="client">
                  {client.username}
                </li>
              </Link>
            ))}
          </ul>
          {event.tags && (
            <>
              <h3 className="subheader">Event Tags</h3>
              <ul>
                {event.tags.map((tag) => (
                  <li key={tag.id}>{tag.title}</li>
                ))}
              </ul>
            </>
          )}
          {/* actions for logged in users */}
          {authed && event.active === true && (
            <>
              {!isOwner ? (
                <>
                  {!hasJoinedEvent &&
                    moment(event.date).isAfter(inThreeHours) &&
                    event?.clients?.length !== 4 && (
                      <div className="button-wrapper">
                        <button disabled={disabled} onClick={handleJoinEvent}>
                          Join Event
                        </button>
                      </div>
                    )}

                  {event?.clients?.length === 4 && !hasJoinedEvent && (
                    <>
                      <div className="section">
                        <p className="big-remark">Sorry, this event is full!</p>
                      </div>
                      <div className="button-wrapper">
                        <button>
                          <Link to="/events/">See other events</Link>
                        </button>
                      </div>
                    </>
                  )}
                  {!hasJoinedEvent &&
                    !moment(event.date).isAfter(inThreeHours) && (
                      <>
                        <div className="section">
                          <p className="big-remark">
                            Sorry, it's too late to join this event!
                          </p>
                        </div>
                        <div className="button-wrapper">
                          <button>
                            <Link to="/events/">See other events</Link>
                          </button>
                        </div>
                      </>
                    )}
                  {hasJoinedEvent && (
                    <div className="button-wrapper">
                      <button
                        className="warning-btn"
                        disabled={disabled}
                        onClick={handleUnjoinEvent}
                      >
                        Leave the Event
                      </button>
                    </div>
                  )}
                </>
              ) : (
                <div className="button-wrapper">
                  <button
                    className="warning-btn"
                    disabled={disabled}
                    onClick={() => toggleIsModalOpen(!isModalOpen)}
                  >
                    Cancel Event
                  </button>
                </div>
              )}
            </>
          )}
          {authed && event.active === false && (
            <div className="section">
              <p className="error-message">
                This event is canceled or passed already
              </p>
              <div className="button-wrapper">
                <button className="btn">
                  <Link to="../events">See other events</Link>
                </button>
              </div>
            </div>
          )}
          {/* end actions for logged in users */}
        </div>
      ) : (
        <div>Loading...</div>
      )}
    </>
  );
}

export default Event;
