import { useState, useEffect } from "react";

import { apiRoot } from "../constants";

const useDish = (id) => {
  const [dish, setDish] = useState(null);
  const [error, setError] = useState(false);

  useEffect(() => {
    fetch(`${apiRoot}/dishes/${id}`, {
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((resp) => {
        if (resp.status === 200) {
          resp.json().then((json) => {
            setDish(json);
            setError(false);
          });
        } else {
          setError(true);
          setDish([]);
        }
      })
      .catch((err) => {
        setError(true);
        setDish([]);
      });
  }, [id]);

  return {
    dish,
    error,
  };
};

export default useDish;
