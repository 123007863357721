import { Link } from "react-router-dom";

import useLocations from "../hooks/useLocations";

function RestaurantList() {
  const { locations, error } = useLocations();

  return (
    <>
      {error && <p className="error-message">Something went wrong...</p>}
      <h1>Restaurants</h1>
      <div className="block-wrapper">
        {locations?.map((location, key) => (
          <div key={key} className="block">
            <h2>
              <Link to={`/restaurants/${location.id}`}>{location.title}</Link>
            </h2>
            <span>{location.city?.title}</span>
          </div>
        ))}
      </div>
    </>
  );
}

export default RestaurantList;
