import { Link } from "react-router-dom";

import moment from "moment-timezone";

import useEvents from "../hooks/useEvents";

import "../App.css";

function EventList() {
  const { events, error } = useEvents();

  // Only show events that are at least 3 hours ahead
  const inThreeHours = moment().add(3, "h");

  const activeEvents = events.filter((event) => {
    return moment(event.date).isAfter(inThreeHours);
  });

  return (
    <>
      {error && <p className="error-message">Something went wrong...</p>}
      <h1>Upcoming Events</h1>
      <div className="block-wrapper">
        {activeEvents?.map((event, key) => (
          <div key={key} className="block">
            <Link to={`/events/${event.id}`}>
              <h2>{event.title}</h2>
            </Link>
            <h3 className="subheader">Restaurant</h3>
            <span>{event.location?.title}</span>
            <h3 className="subheader">When?</h3>
            <p>
              {moment(event.date)
                .tz(event.timezone)
                .format("dddd, MMMM Do · h:mm a")}
            </p>
            <h3 className="subheader">Who's coming</h3>
            <ul>
              {event.clients.map((client, key) => (
                <li key={key} className="client">
                  {client.username}
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </>
  );
}

export default EventList;
