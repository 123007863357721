import { useState, useEffect } from "react";

import { useAuth } from "./useAuth";

import { apiRoot } from "../constants";

const useEvent = (id) => {
  const [event, setEvent] = useState(null);
  const [error, setError] = useState(false);

  const [isOwner, setIsOwner] = useState(false);
  const [hasJoinedEvent, setHasJoinedEvent] = useState(false);

  const { token, profile } = useAuth();

  useEffect(() => {
    // Currently no token needed to be able to see event
    if (!id) return;
    fetch(`${apiRoot}/events/${id}`, {
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((resp) => {
        if (resp.status === 200) {
          resp.json().then((json) => {
            setEvent(json);
            setError(false);
            if (profile) {
              const foundClient = json.clients.find(
                (client) => client.id === profile.id
              );
              setHasJoinedEvent(foundClient);
              const io = json?.author.id === profile?.id;
              setIsOwner(io);
            }
          });
        } else {
          setError(true);
          setEvent([]);
        }
      })
      .catch((err) => {
        setError(true);
        setEvent([]);
      });
  }, [id, profile]);

  const createEvent = (data) => {
    return new Promise((res) => {
      fetch(`${apiRoot}/events/`, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          Authorization: "Token " + token,
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          if (response.status !== 201) {
            setError(true);
            return;
          }
          return response.json();
        })
        .then((json) => {
          res(json.id);
        })
        .catch((err) => {
          setError(true);
        });
    });
  };

  const joinEvent = (id) => {
    return new Promise((res) => {
      fetch(`${apiRoot}/events/${id}/join/`, {
        method: "POST",
        headers: {
          Authorization: `Token ${token}`,
          "Content-Type": "application/json",
        },
      })
        .then((resp) => {
          resp.json().then(() => {
            if (resp.status === 200) {
              res(true);
              setError(false);
              const foundClient = event.clients.find(
                (client) => client.id === profile.id
              );
              if (!foundClient) {
                setEvent({
                  ...event,
                  clients: [...event.clients, { ...profile }],
                });
                setHasJoinedEvent(true);
              }
            } else {
              setError(true);
              res(false);
            }
          });
        })
        .catch(() => {
          setError(true);
          res(false);
        });
    });
  };

  const unjoinEvent = (id) => {
    return new Promise((res) => {
      fetch(`${apiRoot}/events/${id}/unjoin/`, {
        method: "POST",
        headers: {
          Authorization: `Token ${token}`,
          "Content-Type": "application/json",
        },
      })
        .then((resp) => {
          resp.json().then(() => {
            if (resp.status === 200) {
              res(true);
              setError(false);
              const foundClient = event.clients.find(
                (client) => client.id === profile.id
              );
              if (foundClient) {
                const remainingClients = event.clients.filter(
                  (client) => client.id !== profile.id
                );
                setEvent({
                  ...event,
                  clients: remainingClients,
                });
                setHasJoinedEvent(false);
              }
            } else {
              setError(true);
              res(false);
            }
          });
        })
        .catch(() => {
          setError(true);
          res(false);
        });
    });
  };

  const cancelEvent = (id) => {
    return new Promise((res) => {
      fetch(`${apiRoot}/events/${id}/cancel/`, {
        method: "POST",
        headers: {
          Authorization: `Token ${token}`,
          "Content-Type": "application/json",
        },
      })
        .then((resp) => {
          resp.json().then(() => {
            if (resp.status === 200) {
              res(true);
              setError(false);
            } else {
              setError(true);
              res(false);
            }
          });
        })
        .catch(() => {
          setError(true);
          res(false);
        });
    });
  };

  return {
    event,
    hasJoinedEvent,
    isOwner,
    createEvent,
    joinEvent,
    unjoinEvent,
    cancelEvent,
    error,
  };
};

export default useEvent;
