import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import "./App.css";

import { AuthProvider } from "./hooks/useAuth";
import ToastProvider from "./Components/ToastProvider";
import ToastShelf from "./Components/ToastShelf";
import ProtectedRoute from "./Components/ProtectedRoute";
import Contact from "./Views/Contact";
import Dashboard from "./Views/Dashboard";
import ForgotPassword from "./Views/ForgotPassword";
import ConfirmingEmail from "./Views/ConfirmingEmail";
import ResendEmailConfirm from "./Views/ResendEmailConfirm";
import HomePage from "./Views/HomePage";
import CreateEvent from "./Views/CreateEvent";
import Dish from "./Views/Dish";
import DishList from "./Views/DishList";
import Event from "./Views/Event";
import EventList from "./Views/EventList";
import Login from "./Views/Login";
import Notifications from "./Views/Notifications";
import DashboardProfile from "./Views/DashboardProfile";
import Register from "./Views/Register";
import ResetPassword from "./Views/ResetPassword";
import Restaurant from "./Views/Restaurant";
import RestaurantList from "./Views/RestaurantList";
import RootLayout from "./Views/Root";
import UserProfile from "./Views/UserProfile";
import EditProfile from "./Views/EditProfile";
// ToDo: Add Error Component

function App() {
  return (
    <Router>
      <ToastProvider>
        <ToastShelf />
        <AuthProvider>
          <Routes>
            <Route path="/" element={<RootLayout />}>
              <Route path="/" element={<HomePage />}></Route>
              <Route path="/contact" element={<Contact />}></Route>
              <Route
                path="/dashboard"
                element={
                  <ProtectedRoute>
                    <Dashboard />
                  </ProtectedRoute>
                }
              ></Route>
              <Route
                path="dashboard/notifications"
                element={
                  <ProtectedRoute>
                    <Notifications />
                  </ProtectedRoute>
                }
              />
              <Route path="dashboard/profile" element={<DashboardProfile />} />
              <Route path="/dishes" element={<DishList />}></Route>
              <Route path="/dishes/:id" element={<Dish />}></Route>
              <Route path="/events" element={<EventList />}></Route>
              <Route path="/events/:id" element={<Event />}></Route>
              <Route path="/login" element={<Login />}></Route>
              <Route path="/register" element={<Register />}></Route>
              <Route
                path="/forgot-password"
                element={<ForgotPassword />}
              ></Route>
              <Route
                path="/resend-email-confirm"
                element={<ResendEmailConfirm />}
              ></Route>              
              <Route
                path="/reset-password/:id/:token"
                element={<ResetPassword />}
              ></Route>
              <Route
                path="/confirming-email/:token"
                element={<ConfirmingEmail />}
              ></Route>              
              <Route path="/restaurants" element={<RestaurantList />}></Route>
              <Route path="/restaurants/:id" element={<Restaurant />}></Route>
              <Route
                path="/restaurants/:id/create"
                element={
                  <ProtectedRoute>
                    <CreateEvent />
                  </ProtectedRoute>
                }
              ></Route>
              <Route
                path="/users/:username"
                element={
                  <ProtectedRoute>
                    <UserProfile />
                  </ProtectedRoute>
                }
              ></Route>
              <Route
                path="/dashboard/profile/edit"
                element={
                  <ProtectedRoute>
                    <EditProfile />
                  </ProtectedRoute>
                }
              ></Route>
            </Route>
          </Routes>
        </AuthProvider>
      </ToastProvider>
    </Router>
  );
}

export default App;
