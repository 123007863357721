import { useContext, useState } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";

import { useAuth } from "../hooks/useAuth";

import { ToastContext } from "../Components/ToastProvider";

import "../App.css";

function Login(id) {
  const { createToast, dismissToast } = useContext(ToastContext);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const { login, errors } = useAuth();
  const navigate = useNavigate();
  const { state } = useLocation();

  const handleLogin = (event) => {
    const delay = async (ms) => {
      return new Promise((resolve) => setTimeout(resolve, ms));
    };
    event.preventDefault();
    dismissToast(id);
    if (!email || !password) {
      createToast("Please enter email and password", "warning");
      return;
    }
    login(email, password).then(async (success) => {
      if (success) {
        await delay(500);
        createToast("Yay, let's take you to your Dashboard! 🚀", "success");
        await delay(3000);
        navigate(state?.path || "/dashboard");
        dismissToast(id);
      } else {
        createToast("Something went wrong. Please try again.", "error");
      }
    });
  };

  return (
    <>
      <h1>Login</h1>

      {Object.keys(errors).map((key, i) => (
        <div key={i}>
          {errors["non_field_errors"]?.map((errorMessage, key) => (
            <div key={key}>
              {errorMessage ===
                "Unable to log in with provided credentials." && (
                <div>
                  <p className="error-message max-w-500">
                    Please check your email and/or password and try again.
                  </p>
                  <p>Are you new? Click to register below.</p>
                </div>
              )}
              {errorMessage === "E-mail is not verified." && (
                <div>
                  <p className="error-message max-w-500">
                    Please confirm your email before logging in. You should have
                    received an email from us with a link to click.
                  </p>
                  <p>
                    <Link to="/resend-email-confirm">
                      Resend Email Confirm Link
                    </Link>
                  </p>
                </div>
              )}
            </div>
          ))}
        </div>
      ))}

      <form id="login-form" onSubmit={handleLogin}>
        <label htmlFor="email">Your Email</label>
        <input
          id="email"
          type="email"
          required
          value={email}
          onChange={(event) => {
            setEmail(event.target.value);
          }}
        ></input>
        <label htmlFor="password">Your Password</label>
        <input
          id="password"
          type="password"
          required
          value={password}
          onChange={(event) => {
            setPassword(event.target.value);
          }}
        ></input>
        <button>Login</button>
        <p className="margin-t-40 underline">
          <Link to="/forgot-password">Forgot Your Password?</Link>
        </p>
        <p className="underline">
          <Link to="/register">Register</Link>
        </p>
      </form>
    </>
  );
}

export default Login;
