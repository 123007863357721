import { useContext, useState } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";

import { useAuth } from "../hooks/useAuth";

import { ToastContext } from "../Components/ToastProvider";

import "../App.css";

function Register(id) {
  const { createToast, dismissToast } = useContext(ToastContext);

  const { register, errors } = useAuth();
  const navigate = useNavigate();
  const { state } = useLocation();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [email, setEmail] = useState("");

  const handleSubmit = (event) => {
    const delay = async (ms) => {
      return new Promise((resolve) => setTimeout(resolve, ms));
    };
    event.preventDefault();
    dismissToast(id);

    if (password !== passwordConfirm) {
      createToast("The two passwords don't match.", "error");
      return;
    }
    register(email, password, passwordConfirm, username).then(
      async (success) => {
        if (success) {
          await delay(500);
          createToast(
            "Success!  Please check your inbox for a link to confirm your email.",
            "success"
          );
          await delay(3000);
          navigate(state?.path || "/");
        } else {
          createToast("Please try again.", "error");
        }
      }
    );
  };

  return (
    <>
      <h1>Register</h1>
      {Object.keys(errors).map((key, i) => (
        <div key={i}>
          {errors[key]?.map((errorMessage, i) => (
            <div key={key}>
              {key === "email" && (
                <div>
                  <p className="error-message max-w-500">
                    Please enter a different email. If this email is yours and
                    you have already registered, please confirm your email
                    address by clicking the link in the message we sent to your
                    email.
                  </p>
                  <p className="underline">
                    <Link to="/resend-email-confirm">
                      Resend Email Confirm Link
                    </Link>
                  </p>
                </div>
              )}
              {key === "username" && (
                <div>
                  <p class="error-message max-w-500">
                    Please choose a different username
                  </p>
                </div>
              )}
            </div>
          ))}
        </div>
      ))}
      <form onSubmit={handleSubmit} id="register-form">
        <label htmlFor="username">Your Username</label>
        <p className="remark">The way you show up on Mealbuddy</p>
        <input
          id="username"
          type="text"
          required
          value={username}
          onChange={(event) => {
            setUsername(event.target.value);
          }}
        ></input>
        <label htmlFor="email">Your Email</label>
        <input
          id="email"
          type="email"
          required
          value={email}
          onChange={(event) => {
            setEmail(event.target.value);
          }}
        ></input>
        <label htmlFor="password">Your Password</label>
        <p className="remark">
          Must contain at least one number and one uppercase and lowercase
          letter, and at least 8 or more characters
        </p>
        <input
          id="password"
          type="password"
          required
          value={password}
          minLength="8"
          pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
          onChange={(event) => {
            setPassword(event.target.value);
          }}
        ></input>
        <label htmlFor="password2">Confirm Password</label>
        <input
          id="password2"
          type="password"
          required
          value={passwordConfirm}
          minLength="8"
          pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
          onChange={(event) => {
            setPasswordConfirm(event.target.value);
          }}
        ></input>
        <button>Register</button>
        <p className="margin-t-40 underline">
          <Link to="/login">Login</Link>
        </p>
      </form>
    </>
  );
}

export default Register;
