import { Link } from "react-router-dom";

import useDashboardProfile from "../hooks/useDashboardProfile";
import useDashboard from "../hooks/useDashboard";
import useNotifications from "../hooks/useNotifications";

import moment from "moment-timezone";

const Dashboard = () => {
  const [profile, error] = useDashboardProfile();
  const { myEvents, error: myEventsError } = useDashboard();
  const { notifications, error: notificationError } = useNotifications();

  const threeNotifications = notifications.slice(0, 3);

  const myActiveEvents = myEvents.filter((event) => {
    return event.active === true && moment(event.date).isAfter(moment());
  });

  const myNotActiveEvents = myEvents.filter((event) => {
    return moment(event.date).isBefore(moment()) || event.active === false;
  });

  return (
    <>
      {error ||
        myEventsError ||
        (notificationError && <p>Something went wrong</p>)}
      <h1>Dashboard</h1>
      {profile && (
        <>
          <p>Hello {profile.username}</p>
          <div className="button-wrapper">
            <Link to={`/dashboard/profile`}>
              <button>See your Profile</button>{" "}
            </Link>
          </div>
        </>
      )}
      <div className="section">
        <h2 className="subheader">Notifications</h2>
        <div className="block-wrapper">
          {threeNotifications?.map((notification, key) => (
            <div key={key} className="thin-block">
              {/* TODO: remove it later */}
              {notification.event_id ? (
                <Link to={`/events/${notification.event_id}`}>
                  <p>{notification.body}</p>
                </Link>
              ) : (
                <p>{notification.body}</p>
              )}
            </div>
          ))}
          {notifications.length > 3 && (
            <div className="button-wrapper">
              <button>
                <Link to="notifications">See all notifications</Link>
              </button>
            </div>
          )}
        </div>
      </div>
      <div className="section">
        <h2 className="subheader">My Upcoming Events</h2>
        <div className="block-wrapper">
          {myActiveEvents?.map((event) => (
            <div key={event.id} className="block">
              {event.author.id === profile.id && (
                <p className="author">You created this event</p>
              )}
              <h3>
                <Link to={`/events/${event.id}`}>{event.title}</Link>
              </h3>
              <p>{event.city?.title}</p>
              <p>
                {moment(event.date)
                  .tz(event.timezone)
                  .format("dddd, MMMM Do · h:mm a")}
              </p>
            </div>
          ))}
        </div>
        <div className="section">
          <h2 className="subheader">Past or Cancelled Events</h2>
          <div className="block-wrapper">
            {myNotActiveEvents?.map((event) => (
              <div key={event.id} className="inactive block">
                {event.author.id === profile.id && (
                  <p className="author">You created this event</p>
                )}
                {event.active === false && <p>Cancelled Event</p>}
                <h3>{event.title}</h3>
                <p>{event.city?.title}</p>
                <p>{moment(event.date).format("dddd, MMMM Do · h:mm a")}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
