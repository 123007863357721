import { Link, useParams } from "react-router-dom";

import { useAuth } from "../hooks/useAuth";
import useLocation from "../hooks/useLocation";

function Restaurant() {
  const { id } = useParams();

  const { authed } = useAuth();
  const { location, error } = useLocation(id);

  return (
    <>
      {error && <p className="error-message">Something went wrong...</p>}
      {location ? (
        <div className="block">
          <h1>{location.title}</h1>
          <p className="p-wrapper">{location.body}</p>

          {location.dishes.length > 0 && (
            <>
              <h3 className="subheader">Specialty Dishes</h3>
              <ul>
                {location.dishes.map((dish) => (
                  <li key={dish.id} className="dish-title">
                    <Link to={`/dishes/${dish.id}`}>{dish.title}</Link>
                  </li>
                ))}
              </ul>
            </>
          )}

          <h3 className="subheader">Address</h3>
          {location.google_maps ? (
            <Link
              to={location.google_maps}
              target="_blank"
              rel="noopener noreferrer"
            >
              <span>{location.address}</span>
            </Link>
          ) : (
            <span>{location.address}</span>
          )}

          {location.opening_hours && (
            <>
              <h3 className="subheader">Opening Hours</h3>
              <span>{location.opening_hours}</span>
            </>
          )}

          {authed && (
            <p>
              <button>
                <Link to={`/restaurants/${id}/create`}>Create Your Event</Link>
              </button>
            </p>
          )}
        </div>
      ) : (
        <div>Loading...</div>
      )}
    </>
  );
}

export default Restaurant;
