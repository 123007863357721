import { useContext, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import useLocation from "../hooks/useLocation";
import useEvent from "../hooks/useEvent";

import moment from "moment-timezone";

import { ToastContext } from "../Components/ToastProvider";
import TimePicker from "../Components/TimePicker";

import "../App.css";

function CreateEvent(toastId) {
  const { id } = useParams();
  const { createToast, dismissToast } = useContext(ToastContext);

  const { location, error } = useLocation(id);
  const { createEvent, errorEvent } = useEvent();

  const twoDays = moment().add(2, "days");
  const twoDaysFormatted = twoDays.format("YYYY-MM-DD");

  const twoMonths = moment().add(2, "months");
  const twoMonthsFormatted = twoMonths.format("YYYY-MM-DD");

  const [disabled, setDisabled] = useState(false);

  let navigate = useNavigate();

  const initialTags = [
    { name: "Girls Only", id: 1, checked: false },
    { name: "Vegan", id: 5, checked: false },
    { name: "Couples", id: 4, checked: false },
    { name: "Entrepreneurs", id: 3, checked: false },
    { name: "Boys Only", id: 2, checked: false },
  ];

  const [tags, setTags] = useState(initialTags);
  const [chosenDish, setChosenDish] = useState();
  const [date, setDate] = useState(twoDaysFormatted);
  const [time, setTime] = useState(moment("12:30", "HH:mm"));
  const [title, setTitle] = useState("");
  const [languages, setLanguages] = useState("");

  const delay = async (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  const setToLocationTimezone = (dateTime, timezone) => {
    const preserveSameTime = true;
    return moment(dateTime).tz(location.city.timezone, preserveSameTime);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Disable create button, so they can not add duplicate events at the same time
    setDisabled(true);

    const dateTime = `${date}T${moment(time).format("HH:mm:ss")}`;

    const dateLocationTimezone = setToLocationTimezone(
      dateTime,
      location.city.timezone
    );

    const utcDate = moment(dateLocationTimezone).utc();
    const utcDateStr = utcDate.toISOString();

    const sendingTags = tags.filter((t) => {
      if (t.checked === true) {
        return true;
      } else {
        return false;
      }
    });

    const finalIds = sendingTags.map((t) => {
      return t.id;
    });

    const data = {
      location_id: location.id,
      title: title,
      date: utcDateStr,
      dish_id: chosenDish,
      tag_ids: finalIds,
      languages: languages,
    };

    createEvent(data).then(async (id) => {
      await delay(1500);
      createToast(
        "You created an event. We're taking you there... 🎆",
        "success"
      );
      await delay(3000);
      navigate(`../../events/${id}`, { replace: true });
      dismissToast(toastId);
    });
  };

  // Need this for our time picker
  function range(start, end) {
    const result = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  }

  const disabledTime = () => {
    // Disable times before 8:00 AM and after 11:45 PM
    return { disabledHours: () => range(0, 24).splice(0, 8) };
  };

  return (
    <>
      {error && <p className="error-message">Something went wrong...</p>}
      {errorEvent && <p className="error-message">Something went wrong...</p>}
      <h1>Create Your Event</h1>
      <form onSubmit={handleSubmit} className="create-form">
        <label htmlFor="title">Your Event's Name</label>
        <input
          id="title"
          type="text"
          required
          placeholder="My Awesome Event"
          value={title}
          onChange={(event) => {
            setTitle(event.target.value);
          }}
        ></input>
        {location ? (
          <>
            <label htmlFor="location">Event's Location</label>
            <input
              id="location"
              type="text"
              disabled={true}
              value={location.title}
            ></input>
          </>
        ) : (
          <div>Loading...</div>
        )}

        <label htmlFor="languages">Language(s) I speak</label>
        <input
          id="languages"
          className="capitalize"
          type="text"
          required
          placeholder="English"
          value={languages}
          onChange={(event) => {
            setLanguages(event.target.value);
          }}
        ></input>

        {location?.dishes.length > 0 && (
          <fieldset>
            <legend>What would you like to try?</legend>
            {location.dishes.map((dish) => (
              <div key={dish.id}>
                <input
                  type="radio"
                  name="chosen-dish"
                  required
                  value={dish.title}
                  checked={chosenDish === dish.id}
                  onChange={(e) => {
                    setChosenDish(dish.id);
                  }}
                ></input>
                <label htmlFor={dish.title} className="radio-label">
                  {dish.title}
                </label>
              </div>
            ))}
          </fieldset>
        )}

        <fieldset>
          <legend>Select tags for your event:</legend>
          {tags?.map((tag) => (
            <div key={tag.id}>
              <input
                type="checkbox"
                value={tag.name}
                checked={tag.checked}
                onChange={(event) => {
                  const newTags = tags.map((t) => {
                    if (t.id === tag.id) {
                      return { ...t, checked: event.target.checked };
                    }
                    return t;
                  });
                  setTags(newTags);
                }}
              />
              <label htmlFor={tag.name}>{tag.name}</label>
            </div>
          ))}
        </fieldset>
        <fieldset>
          <legend>Event's timezone</legend>
          <input
            type="text"
            disabled={true}
            value={location?.city.timezone}
          ></input>
        </fieldset>
        <fieldset>
          <legend>Select the Date for your Event:</legend>
          <input
            id="date"
            value={date}
            type="date"
            name="date"
            aria-label="date"
            required
            min={twoDaysFormatted}
            max={twoMonthsFormatted}
            onChange={(event) => {
              setDate(event.target.value);
            }}
          />
        </fieldset>
        <fieldset>
          <legend>Select the Time for your Event:</legend>
          <TimePicker
            id="time"
            required
            aria-label="time"
            minuteStep={15}
            format="HH:mm"
            showNow={false}
            disabledTime={disabledTime}
            value={time}
            onChange={(t) => {
              setTime(t);
            }}
          ></TimePicker>
        </fieldset>
        <button disabled={disabled}>Create Event</button>
      </form>
    </>
  );
}

export default CreateEvent;
