import { useContext, useEffect } from "react";

import { useNavigate, useParams } from "react-router-dom";

import { useAuth } from "../hooks/useAuth";
import { ToastContext } from "../Components/ToastProvider";

function ConfirmingEmail(id) {
    const { token } = useParams();

      const { confirmEmail } = useAuth();

    const { createToast, dismissToast } = useContext(ToastContext);

    const navigate = useNavigate();
    
    const delay = async (ms) => {
        return new Promise((resolve) => setTimeout(resolve, ms));
      };

    useEffect(() => {
        confirmEmail(token).then(
            async (success) => {
              if (success) {
                await delay(500);
                createToast("Redirecting...", "success");
                await delay(3000);
                navigate("/login");
                dismissToast(id);
              } else {
                createToast("Please try again.", "error");
              }
            }
        );      
    }, []);

  return (
    <div>
      <h1>Thanks for confirming your email.</h1>
      <p>You will now be redirected to login.</p>
    </div>
  );
}

export default ConfirmingEmail;
