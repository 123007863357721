import { Dialog } from "@headlessui/react";

import "../App.css";

function Modal({ title, description, isOpen, handleDismiss, children }) {
  return (
    <Dialog className="modal wrapper" open={isOpen} onClose={handleDismiss}>
      <div className="backdrop" onClick={handleDismiss} />
      <Dialog.Panel className="dialog">
        <Dialog.Title>{title}</Dialog.Title>
        {description && <Dialog.Description>{description}</Dialog.Description>}
        {children}
      </Dialog.Panel>
    </Dialog>
  );
}

export default Modal;
