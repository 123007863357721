import { Link } from "react-router-dom";

import useDishes from "../hooks/useDishes";

function DishList() {
  const { dishes, error } = useDishes();

  return (
    <>
      {error && <p className="error-message">Something went wrong...</p>}
      <h1>Best Dishes in Your Area</h1>
      <div className="block-wrapper">
        {dishes?.map((dish, key) => (
          <div key={key} className="block">
            <Link to={`/dishes/${dish.id}`}>
              <div className="image-wrapper">
                <img
                  src={dish.image_url}
                  className="dish-list-image"
                  alt={dish.title}
                />
              </div>
              <h2>{dish.title}</h2>
            </Link>
          </div>
        ))}
      </div>
    </>
  );
}

export default DishList;
