import { useContext, useState } from "react";

import { Link, useNavigate } from "react-router-dom";

import { useAuth } from "../hooks/useAuth";
import { ToastContext } from "../Components/ToastProvider";

function ForgotPassword(id) {
  const { requestPasswordReset } = useAuth();

  const { createToast, dismissToast } = useContext(ToastContext);

  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [disabled, setDisabled] = useState(false);

  const handleSubmit = (event) => {
    const delay = async (ms) => {
      return new Promise((resolve) => setTimeout(resolve, ms));
    };

    event.preventDefault();
    dismissToast(id);

    requestPasswordReset(email).then(async (success) => {
      if (success) {
        setDisabled(true);
        await delay(500);
        createToast(
          "You will receive an email from us shortly, if your email is registered already. 💌",
          "success"
        );
        await delay(3000);
        navigate("/login");
        dismissToast(id);
      } else {
        createToast("Something went wrong. Please try again", "error");
      }
    });
  };
  return (
    <div>
      <h1>Reset Password</h1>
      <p>
        Just enter the email address you registered with and <br /> we’ll send
        you a link to reset your password.
      </p>
      <form id="forgot-password-form" onSubmit={handleSubmit}>
        <label htmlFor="email">Your Email</label>
        <input
          id="email"
          type="email"
          required
          value={email}
          onChange={(event) => {
            setEmail(event.target.value);
          }}
        ></input>
        <button disabled={disabled}>Send Password Reset Link</button>
        <p className="margin-t-40 underline">
          <Link to="/login">Login</Link>
        </p>
        <p className="underline">
          <Link to="/register">Register</Link>
        </p>
      </form>
    </div>
  );
}

export default ForgotPassword;
