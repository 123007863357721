import * as React from "react";
import DatePicker from "./MyDatePicker";

const TimePicker = React.forwardRef((props, ref) => (
  <DatePicker {...props} picker="time" mode={undefined} ref={ref} />
));

TimePicker.displayName = "TimePicker";

export default TimePicker;
