import { useContext, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import { useAuth } from "../hooks/useAuth";
import { ToastContext } from "../Components/ToastProvider";

function ResetPassword() {
  const { id, token } = useParams();
  const navigate = useNavigate();

  const { resetPassword } = useAuth();
  const { createToast, dismissToast } = useContext(ToastContext);

  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [disabled, setDisabled] = useState(false);

  const handleResetPassword = (event) => {
    const delay = async (ms) => {
      return new Promise((resolve) => setTimeout(resolve, ms));
    };

    event.preventDefault();

    if (password !== passwordConfirm) {
      createToast("The two passwords don't match.", "error");
      return;
    }

    resetPassword(password, passwordConfirm, id, token).then(
      async (success) => {
        if (success) {
          setDisabled(true);
          await delay(500);
          createToast(
            "You successfully changed your password, now let's take you to the Login page",
            "success"
          );
          await delay(3000);
          navigate("/login");
          dismissToast(id);
        } else {
          createToast("Something went wrong. Please try again", "error");
        }
      }
    );
  };

  return (
    <div>
      <h1>Reset Password</h1>
      <form id="reset-password-form" onSubmit={handleResetPassword}>
        <label htmlFor="password">Your New Password</label>
        <p className="remark">
          Must contain at least one number and one uppercase and lowercase
          letter, and at least 8 or more characters
        </p>
        <input
          id="password"
          type="password"
          value={password}
          minLength="8"
          pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
          onChange={(event) => {
            setPassword(event.target.value);
          }}
        ></input>
        <label htmlFor="password2">Confirm Your New Password</label>
        <input
          id="password2"
          type="password"
          value={passwordConfirm}
          minLength="8"
          pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
          onChange={(event) => {
            setPasswordConfirm(event.target.value);
          }}
        ></input>
        <button disabled={disabled}>Save</button>
        <p className="margin-t-40 underline">
          <Link to="/login">Login</Link>
        </p>
        <p className="underline">
          <Link to="/register">Register</Link>
        </p>
      </form>
    </div>
  );
}

export default ResetPassword;
