import { Link } from "react-router-dom";

import { useAuth } from "../hooks/useAuth";
import userPhoto from "../assets/def_profile_photo.png";

import { Edit } from "react-feather";

function DashboardProfile() {
  const { profile, error } = useAuth();
  return (
    <div>
      {error && <p className="error-message">Something went wrong...</p>}
      {profile && (
        <div className="profile">
          <Link to={`/dashboard/profile/edit`} className="icon-button">
            <Edit size={19} />
            <span className="icon-text">Edit</span>
          </Link>
          <h1 className="capitalize">Your Profile </h1>
          <img alt="a bowl of noodles" className="user-photo" src={userPhoto} />
          <h3 className="subheader">Your Username</h3>
          <span>{profile.username}</span>
          <h3 className="subheader">Your Name</h3>
          <span>{profile.name}</span>
          <h3 className="subheader">Your E-mail</h3>
          <span>{profile.email}</span>
          <h3 className="subheader">Bio</h3>
          <span>{profile.bio}</span>
          <h3 className="subheader">Your Favorite Food</h3>
          <span>{profile.favorite_food}</span>
        </div>
      )}
    </div>
  );
}

export default DashboardProfile;
