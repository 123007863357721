import { useParams, Link } from "react-router-dom";

import useDish from "../hooks/useDish";

function Dish() {
  const { id } = useParams();
  const { dish, error } = useDish(id);

  return (
    <>
      {error && <p className="error-message">Something went wrong...</p>}
      {dish ? (
        <div className="block-wrapper">
          <div className="block">
            <div className="image-wrapper">
              <img
                src={dish.image_url}
                className="dish-image"
                alt={dish.title}
              />
            </div>
            <h1>{dish.title}</h1>
            <p>{dish.body}</p>
            <h3>Best Restaurants to Try</h3>
            <ul>
              {dish.locations.map((location) => (
                <li key={location.id}>
                  <Link to={`/restaurants/${location.id}`}>
                    {location.title}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
      ) : (
        <div>Loading...</div>
      )}
    </>
  );
}

export default Dish;
