import { useState, useEffect } from "react";

import { apiRoot } from "../constants";

const useDishes = () => {
  const [dishes, setDishes] = useState([]);
  const [error, setError] = useState(false);

  useEffect(() => {
    fetch(`${apiRoot}/dishes/`, {
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((resp) => {
        if (resp.status === 200) {
          resp.json().then((json) => {
            setDishes(json);
            setError(false);
          });
        } else {
          setError(true);
          setDishes([]);
        }
      })
      .catch((err) => {
        setError(true);
        setDishes([]);
      });
  }, []);

  return {
    dishes,
    error,
  };
};

export default useDishes;
