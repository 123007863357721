import { useParams } from "react-router-dom";

import useUserProfile from "../hooks/useUserProfile";

import userPhoto from "../assets/def_profile_photo.png";

function UserProfile() {
  const { username } = useParams();
  const [userProfile, error] = useUserProfile(username);
  return (
    <div>
      {error && <p className="error-message">Something went wrong...</p>}
      {userProfile && (
        <div className="profile">
          <h1 className="capitalize">User Profile</h1>
          <img alt="a bowl of noodles" className="user-photo" src={userPhoto} />
          <h3 className="subheader">Username</h3>
          <span>{userProfile.username}</span>
          <h3 className="subheader">Bio</h3>
          <span>{userProfile.bio}</span>
          <h3 className="subheader">Favorite Food</h3>
          <span>{userProfile.favorite_food}</span>
        </div>
      )}
    </div>
  );
}

export default UserProfile;
