import { Link } from "react-router-dom";

import useNotifications from "../hooks/useNotifications";

function Notifications() {
  const { notifications, error } = useNotifications();

  return (
    <>
      {error && <p>Something went wrong</p>}
      <h1 className="">Notifications</h1>
      <div className="section">
        <div className="block-wrapper">
          {notifications?.map((notification) => (
            <div key={Math.random()} className="thin-block">
              {/* TODO: remove it later */}
              {notification.event_id ? (
                <Link to={`/events/${notification.event_id}`}>
                  <p>{notification.body}</p>
                </Link>
              ) : (
                <p>{notification.body}</p>
              )}
            </div>
          ))}
        </div>
        <div className="button-wrapper">
          <button>
            <Link to="/dashboard">Back to Dashboard</Link>
          </button>
        </div>
      </div>
    </>
  );
}

export default Notifications;
