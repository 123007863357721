import { useEffect, useState } from "react";

import { apiRoot } from "../constants";

const useEvents = () => {
  const [events, setEvents] = useState([]);
  const [error, setError] = useState(false);

  useEffect(() => {
    fetch(`${apiRoot}/events/`, {
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((resp) => {
        if (resp.status === 200) {
          resp.json().then((json) => {
            setEvents(json);
            setError(false);
          });
        } else {
          setError(true);
          setEvents([]);
        }
      })
      .catch((err) => {
        setError(true);
        setEvents([]);
      });
  }, []);

  return {
    events,
    error,
  };
};

export default useEvents;
