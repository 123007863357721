import { useContext, useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";

import { useAuth } from "../hooks/useAuth";

import { ToastContext } from "../Components/ToastProvider";

function EditProfile(id) {
  const navigate = useNavigate();

  const { createToast, dismissToast } = useContext(ToastContext);

  const { profile, error, updateUser, changePassword } = useAuth();

  const [name, setName] = useState("");
  const [bio, setBio] = useState("");
  const [favoriteFood, setFavoriteFood] = useState("");

  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");

  useEffect(() => {
    if (!profile) return;
    setName(profile.name);
    setBio(profile.bio);
    setFavoriteFood(profile.favorite_food);
  }, [profile]);
  const delay = async (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  const handleUpdateUser = (event) => {
    event.preventDefault();
    updateUser({ name, bio, favoriteFood }).then(async (success) => {
      if (success) {
        await delay(500);
        createToast("You succesfully updated your profile 🚀", "success");
        await delay(3000);
        navigate(`/users/${profile.username}`);
        dismissToast(id);
      }
    });
  };

  const handleChangePassword = (event) => {
    event.preventDefault();
    if (password !== passwordConfirm) {
      createToast("The two passwords don't match.", "error");
      return;
    }
    changePassword(password, passwordConfirm, profile.id).then(
      async (success) => {
        if (success) {
          await delay(500);
          createToast("You succesfully changed your password", "success");
          await delay(3000);
          navigate(`/users/${profile.username}`);
          dismissToast(id);
        } else {
          console.log("error");
        }
      }
    );
  };

  return (
    <div>
      {error && <p className="error-message">Something went wrong...</p>}
      {!profile && "loading..."}
      {profile && (
        <>
          <div className="padding-b-3">
            <h1 className="capitalize">Edit Profile </h1>
            <form id="update-user" onSubmit={handleUpdateUser}>
              <label htmlFor="username">Your Username</label>
              <input
                id="username"
                disabled
                type="text"
                value={profile.username}
              />
              <label htmlFor="name">Your Name</label>
              <p className="remark">Hidden on your profile</p>
              <input
                id="name"
                type="text"
                value={name}
                required
                onChange={(event) => {
                  setName(event.target.value);
                }}
              />
              <label htmlFor="email">Your Email</label>
              <p className="remark">Hidden on your profile</p>
              <p className="remark margin-b-10">
                Contact us if need to change your email
              </p>
              <input
                id="email"
                type="email"
                value={profile.email}
                disabled
              ></input>
              <label htmlFor="favoriteFood">Your Favorite Food</label>
              <input
                id="favoriteFood"
                type="text"
                value={favoriteFood}
                required
                onChange={(event) => {
                  setFavoriteFood(event.target.value);
                }}
              />
              <label htmlFor="bio">Your Bio</label>
              <textarea
                id="bio"
                value={bio}
                required
                rows="10"
                onChange={(event) => {
                  setBio(event.target.value);
                }}
              ></textarea>
              <button>Save</button>
            </form>
          </div>
          <div className="section">
            <h2>Change Password</h2>
            <form id="change-password" onSubmit={handleChangePassword}>
              <label htmlFor="password">Your New Password</label>
              <p className="remark">
                Must contain at least one number and one uppercase and lowercase
                letter, and at least 8 or more characters
              </p>
              <input
                id="password"
                type="password"
                value={password}
                minLength="8"
                pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                onChange={(event) => {
                  setPassword(event.target.value);
                }}
              ></input>
              <label htmlFor="password2">Confirm Your New Password</label>
              <input
                id="password2"
                type="password"
                value={passwordConfirm}
                minLength="8"
                pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                onChange={(event) => {
                  setPasswordConfirm(event.target.value);
                }}
              ></input>
              <button>Save</button>
            </form>
          </div>
        </>
      )}
    </div>
  );
}

export default EditProfile;
