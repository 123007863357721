import { useContext } from "react";
import { NavLink, useNavigate } from "react-router-dom";

import { useAuth } from "../hooks/useAuth";
import { ToastContext } from "./ToastProvider";

import ToastShelf from "./ToastShelf";
import "../App.css";

function Navigation(id) {
  const { authed, logout } = useAuth();
  const { createToast, dismissToast } = useContext(ToastContext);

  const navigate = useNavigate();

  const handleLogout = async () => {
    const delay = async (ms) => {
      return new Promise((resolve) => setTimeout(resolve, ms));
    };
    await delay(500);
    createToast("We are logging you out...", "success");
    await delay(3000);
    logout();
    navigate("/");
    dismissToast(id);
  };
  return (
    <>
      <header className="header">
        <nav>
          <ul className="list">
            <li>
              <NavLink end to="/">
                Home
              </NavLink>
            </li>
            <li>
              <NavLink to="/dishes">Dishes</NavLink>
            </li>
            <li>
              <NavLink to="/events">Events</NavLink>
            </li>
            <li>
              <NavLink to="/restaurants">Restaurants</NavLink>
            </li>
            <li>
              <NavLink to="/contact">Contact</NavLink>
            </li>
            {/* <li>
            <NavLink to="/dishes">Dishes</NavLink>
          </li> */}
            {authed ? (
              <>
                <li>
                  <NavLink to="dashboard">Dashboard</NavLink>
                </li>
                <li>
                  <button className="logout" onClick={handleLogout} to="/d">
                    Logout
                  </button>
                </li>
              </>
            ) : (
              <>
                <li>
                  <NavLink to="register">Register</NavLink>
                </li>
                <li>
                  <NavLink to="login">Login</NavLink>
                </li>
              </>
            )}
          </ul>
        </nav>
      </header>
      <ToastShelf />
    </>
  );
}

export default Navigation;
