import { useContext } from "react";

import {
  AlertOctagon,
  AlertTriangle,
  CheckCircle,
  Info,
  X,
} from "react-feather";

import { ToastContext } from "../Components/ToastProvider";

import VisuallyHidden from "../Components/VisuallyHidden";

const ICONS_BY_VARIANT = {
  notice: Info,
  warning: AlertTriangle,
  success: CheckCircle,
  error: AlertOctagon,
};

function Toast({ id, variant, message }) {
  const { dismissToast } = useContext(ToastContext);

  const Icon = ICONS_BY_VARIANT[variant];

  return (
    <div className={`toast ${variant}`}>
      <div className="iconContainer">
        <Icon size={24} />
      </div>
      <p className="content">
        <VisuallyHidden>{variant}</VisuallyHidden>
        {message}
      </p>
      <button
        className="closeButton"
        onClick={() => dismissToast(id)}
        aria-label="Dismiss message"
        aria-live="off"
      >
        <X size={24} />
      </button>
    </div>
  );
}

export default Toast;
