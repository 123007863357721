import { useState, useEffect } from "react";

import { apiRoot } from "../constants";
import { useAuth } from "./useAuth";

const useUserProfile = (username) => {
  const [userProfile, setUserProfile] = useState(null);
  const [error, setError] = useState(false);

  const { token } = useAuth();

  useEffect(() => {
    fetch(`${apiRoot}/users/${username}`, {
      headers: {
        Authorization: `Token ${token}`,
        "Content-Type": "application/json",
      },
    })
      .then((resp) => {
        if (resp.status === 200) {
          resp.json().then((json) => {
            setUserProfile(json);
            setError(false);
          });
        } else {
          setError(true);
          setUserProfile(null);
        }
      })
      .catch((err) => {
        setError(true);
        setUserProfile(null);
      });
  }, [username, token]);

  return [userProfile, error];
};

export default useUserProfile;
